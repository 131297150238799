<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card class="elevation-12">
      <v-toolbar flat>
        <v-toolbar-title>{{ imageTitle }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-img
          max-width="100%"
          :src="imageSrc"
        ></v-img>
      </v-card-text>
      <v-card-text>
        <!-- <form method="get" :action="imageSrc"><v-btn text type="submit">Download</v-btn></form> -->
        <v-btn text @click="downloadImage">Download</v-btn>
        <v-btn text @click="closeDialog">Close</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogMixin from "@/mixins/dialogMixin";

export default {
  props: {
    imageTitle: {
      type: String,
    },
    imageSrc: {
      type: String,
    },
  },
  created(){
    this.showDialog()
  },
  methods:{
    // convertImgToBase64URL(url, callback, outputFormat){
    //   var img = new Image();
    //   img.crossOrigin = 'Anonymous';
    //   img.onload = function(){
    //     var canvas = document.createElement('CANVAS'),
    //     ctx = canvas.getContext('2d'), dataURL;
    //     canvas.height = img.height;
    //     canvas.width = img.width;
    //     ctx.drawImage(img, 0, 0);
    //     dataURL = canvas.toDataURL(outputFormat);
    //     callback(dataURL);
    //     canvas = null; 
    //   };
    //   img.src = url;
    // },
    async downloadImage(){
      // this.convertImgToBase64URL(this.imageSrc, function(base64Img){
      //   console.log(base64Img);
      // });
      var link=document.createElement("a");
      if(link.download!==undefined){
        let blob = await fetch(this.imageSrc).then(r => r.blob()), // let blob = new Blob([this.imageSrc]),
          url=URL.createObjectURL(blob);
        // get file extension
        let parts = this.imageSrc.split("."),
          ext = parts[parts.length - 1];
        link.setAttribute("href",url);
        // link.setAttribute("download",filename);
        // link.setAttribute("href",this.imageSrc);
        link.setAttribute("download",this.imageTitle+"."+ext);
        link.setAttribute("target","_blank");
        link.style.visibility='hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  mixins: [dialogMixin],
};
</script>
